import React, {useEffect, useRef, useState} from 'react';
import {Form, Input, Button, Select, Radio, message, Modal, Card, Descriptions, Upload} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {request} from '@/utils';
import SignatureCanvas from 'react-signature-canvas';
import 'react-phone-number-input/style.css';
import './editPage.css';

const {Option} = Select;

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear - 100; i <= currentYear; i++) {
    years.push(i);
  }
  return years;
};

const getMonths = () => {
  return Array.from({length: 12}, (_, i) => (i + 1).toString().padStart(2, '0'));
};

const getDays = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  return Array.from({length: daysInMonth}, (_, i) => (i + 1).toString().padStart(2, '0'));
};

const DateSelect = ({name, label, initialValue, editable}) => {
  const [year, setYear] = useState(initialValue ? initialValue.split('-')[0] : '');
  const [month, setMonth] = useState(initialValue ? initialValue.split('-')[1] : '');
  const [day, setDay] = useState(initialValue ? initialValue.split('-')[2] : '');

  useEffect(() => {
    if (!editable) {
      setYear(initialValue ? initialValue.split('-')[0] : '');
      setMonth(initialValue ? initialValue.split('-')[1] : '');
      setDay(initialValue ? initialValue.split('-')[2] : '');
    }
  }, [editable, initialValue]);

  return (<Form.Item className="date-select-item" label={label} name={name}>
    <Input.Group compact>
      <Select
        className="date-select-year"
        value={year}
        onChange={setYear}
        disabled={!editable}
        style={{width: '33%'}}
        placeholder="Year"
      >
        {getYears().map((y) => (<Option key={y} value={y}>
          {y}
        </Option>))}
      </Select>
      <Select
        className="date-select-month"
        value={month}
        onChange={setMonth}
        disabled={!editable}
        style={{width: '33%'}}
        placeholder="Month"
      >
        {getMonths().map((m) => (<Option key={m} value={m}>
          {m}
        </Option>))}
      </Select>
      <Select
        className="date-select-day"
        value={day}
        onChange={setDay}
        disabled={!editable}
        style={{width: '34%'}}
        placeholder="Day"
      >
        {getDays(year, month).map((d) => (<Option key={d} value={d}>
          {d}
        </Option>))}
      </Select>
    </Input.Group>
  </Form.Item>);
};

const EditPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {user, editableKeys} = location.state || {};
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [signBoxWidth, setSignBoxWidth] = useState(0);
  const signBoxRef = useRef(null);
  const signatureRef = useRef();
  const [idPhotoBase64, setIdPhotoBase64] = useState(null);
  const [selfIdentImg, setSelfIdentImg] = useState(null);
  const [selfIdentImgSec, setSelfIdentImgSec] = useState(null);
  const [bankAccountImageBase64, setBankAccountImageBase64] = useState(null);
  const [familyMemberResidenceCertificateImageBase64, setFamilyMemberResidenceCertificateImageBase64] = useState(null);
  const [identificationDocumentImageBase64, setIdentificationDocumentImageBase64] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imgType, setImgType] = useState('driversLicense');
  const [accountHolderName, setAccountHolderName] = useState(user.accountHolderName);
  const [japaneseCommonSurname, setJapaneseCommonSurname] = useState(user.japaneseCommonSurname);
  const [japaneseCommonGivenName, setJapaneseCommonGivenName] = useState(user.japaneseCommonGivenName);
  const [urlEditableKeys, setUrlEditableKeys] = useState([]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const keys = query.get('keys') ? query.get('keys').split(',') : [];
    setUrlEditableKeys(keys);
  }, []);

  const genderMapping = {
    male: '男', female: '女',
  };

  const fieldLabels = {
    gender: '性别',
    chineseSurname: '本国姓',
    chineseGivenName: '本国名',
    furiganaSurname: 'フリガナ姓',
    furiganaGivenName: 'フリガナ名',
    englishSurname: 'ローマ字姓',
    englishGivenName: 'ローマ字名',
    japaneseCommonSurname: '日本姓/日本語通称姓',
    japaneseCommonGivenName: '日本名/日本語通称名',
    identificationDocumentImage: '日本語通称姓名記載される証明書',
    selfIdentificationDocumentImage: '身分証明書1',
    selfIdentificationDocumentImage2: '身分証明書2',
    bankAccountImage: '銀行カードまたは通帳の写真',
    accountHolderName: '口座名義',
    familyMemberResidenceCertificateImage: '本人および名義人が記載された住民票（家人名義銀行口座のみ）',
    financialInstitutionName: '金融機関',
    branchName: '支店名',
    accountNumber: '口座番号',
    userEmail: 'メールアドレス',
    phoneNumber: '電話番号',
    postalCode: '郵便番号',
    address: '住所',
    emergencyContactName: '緊急連絡先姓名',
    emergencyContactPhoneNumber: '緊急連絡先電話',
    emergencyContactRelationship: '緊急連絡先関係',
    registrationDate: '契約日付',
    userSignImage: '署名写真',
    idPhoto: '顔写真',
    dateOfBirth: '生年月日',
  };

  // const handleImageUpload = async (file, setImageBase64) => {
  //   const reader = new FileReader();
  //   reader.onload = async () => {
  //     const base64String = reader.result;
  //     setImageBase64(base64String);
  //     setLoading(true);
  //     try {
  //       const response = await fetch('https://sign.jtmh.jp/api/upload', {
  //         method: 'POST',
  //         headers: {'Content-Type': 'application/json'},
  //         body: JSON.stringify({base64Image: base64String, imageType: file.name}),
  //       });
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       if (data.message === 200) {
  //         message.success(data.data);
  //       } else {
  //         message.error(data.data);
  //       }
  //     } catch (error) {
  //       message.error(`Upload failed: ${error.message}`);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   reader.readAsDataURL(file);
  //   return false;
  // };

  const handleImageUpload = async (file, setImageBase64) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      setImageBase64(base64String);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const renderInput = (label, name, component = Input, props = {}) => {
    const isEditable = editableKeys.includes(name);
    const isIdentificationDocumentImageEditable = editableKeys.includes('identificationDocumentImage') || urlEditableKeys.includes('identificationDocumentImage') || (japaneseCommonSurname && japaneseCommonGivenName);
    return (
      <Form.Item className="form-item" label={label} name={name} style={{background: isEditable ? 'none' : '#f0f0f0'}}>
        {React.createElement(component, {
          readOnly: !isEditable, disabled: !isEditable, ...props,
        })}
      </Form.Item>);
  };

  const handleSubmit = async (values) => {

    const signatureData = signatureRef.current.toDataURL(); // 将签名保存为DataURL

    // 组装payload
    const payload = {
      userData: {
        ...values,
        idPhoto: null,
        selfIdentificationDocumentImage: null,
        selfIdentificationDocumentImage2: null,
        bankAccountImage: null,
        familyMemberResidenceCertificateImage: null,
        identificationDocumentImage: null,
        userSignImage: null,
      },
      idPhotoBase64,
      selfIdentificationDocumentImageBase64: selfIdentImg,
      selfIdentificationDocumentImage2Base64: selfIdentImgSec,
      bankAccountImageBase64,
      familyMemberResidenceCertificateImageBase64,
      identificationDocumentImageBase64,
      userSignImageBase64: signatureData,
    };

    setModalData(payload);
    setIsModalVisible(true);
  };

// Modal提交表单的确认界面
  const handleEdit = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      const res = await request.post('/user/submitEdit', modalData);
      if (res.status === 200) {
        message.success('提交成功');
        setIsModalVisible(false);
        navigate('/submittedPage');
      } else {
        message.error(res.message);
      }
    } catch (error) {
      message.error('An unexpected error occurred');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (signBoxRef.current) {
      const width = signBoxRef.current.clientWidth;
      setSignBoxWidth(width);
    }
  }, [signBoxRef]);

  const handleAccountHolderNameChange = (value) => {
    setAccountHolderName(value);
  };

  const handleJapaneseCommonSurnameChange = (e) => {
    setJapaneseCommonSurname(e.target.value);
  };

  const handleJapaneseCommonGivenNameChange = (e) => {
    setJapaneseCommonGivenName(e.target.value);
  };

  const shouldShowIdentificationDocumentImage = () => {
    // 如果 URL 中的 keys 包含 identificationDocumentImage，则显示
    if (urlEditableKeys.includes('identificationDocumentImage')) {
      return true;
    }

    // 如果 JapaneseCommonSurname 或 JapaneseCommonGivenName 被激活，则显示
    if (editableKeys.includes('japaneseCommonSurname') || editableKeys.includes('japaneseCommonGivenName')) {
      return true;
    }

    // 否则，不显示
    return false;
  };

  const shouldShowFamilyMemberResidenceCertificateImage = () => {
    return (editableKeys.includes('familyMemberResidenceCertificateImage') || urlEditableKeys.includes('familyMemberResidenceCertificateImage') || (editableKeys.includes('accountHolderName') && accountHolderName === '家人名義'));
  };

  // 清除签名
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  return (<div className="form_body">
    <Form className="edit-form" layout="vertical" form={form} initialValues={user} onFinish={handleSubmit}>
      {renderInput('ID', 'userId')}
      {renderInput('本国姓', 'chineseSurname')}
      {renderInput('本国名', 'chineseGivenName')}
      {renderInput('フリガナ姓', 'furiganaSurname')}
      {renderInput('フリガナ名', 'furiganaGivenName')}
      {renderInput('ローマ字姓', 'englishSurname')}
      {renderInput('ローマ字名', 'englishGivenName')}
      {renderInput('日本姓/日本語通称姓', 'japaneseCommonSurname', Input, {onChange: handleJapaneseCommonSurnameChange})}
      {renderInput('日本名/日本語通称名', 'japaneseCommonGivenName', Input, {onChange: handleJapaneseCommonGivenNameChange})}
      <Form.Item className="form-item-gender" label="性別" name="gender"
                 style={{background: editableKeys.includes('gender') ? 'none' : '#f0f0f0'}}>
        <Radio.Group disabled={!editableKeys.includes('gender')}>
          <Radio value="male">男</Radio>
          <Radio value="female">女</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item className="form-item-date" label="生年月日" name="dateOfBirth"
                 style={{background: editableKeys.includes('dateOfBirth') ? 'none' : '#f0f0f0'}}>
        <DateSelect
          name="dateOfBirth"
          initialValue={user.dateOfBirth}
          editable={editableKeys.includes('dateOfBirth')}
        />
      </Form.Item>
      {renderInput('メールアドレス', 'userEmail')}
      {renderInput('電話番号', 'phoneNumber')}
      {renderInput('郵便番号', 'postalCode')}
      {renderInput('住所', 'address')}
      <Form.Item className="form-item-image-type"
                 label="身分証明書を選択してください（外国籍の方は必ず在留カードを選択してください）">
        <Select value={imgType} onChange={setImgType} style={{width: 300}}
                disabled={!editableKeys.includes('selfIdentificationDocumentImage')}>
          <Option value="zaiLiuKa">在留カード表面＋裏面</Option>
          <Option value="driversLicense">運転免許証表面＋裏面</Option>
          <Option value="myNumber">保険証表面＋個人番号カード表面</Option>
          <Option value="hoken">保険証表面＋住民票</Option>
        </Select>
      </Form.Item>
      {editableKeys.includes('selfIdentificationDocumentImage') && (<>
        <Form.Item
          className="form-item-upload"
          label={imgType === 'zaiLiuKa' ? '在留カード表面' : imgType === 'driversLicense' ? '運転免許証表面' : imgType === 'myNumber' ? '保険証表面' : '保険証表面'}>
          <Upload
            beforeUpload={(file) => handleImageUpload(file, setSelfIdentImg)}
            listType="picture-card"
            accept="image/*"
            showUploadList={false}
            disabled={!editableKeys.includes('selfIdentificationDocumentImage')}
          >
            {selfIdentImg ? (<div style={{position: 'relative'}}>
              <img src={selfIdentImg} alt="在留カード表面" style={{width: '100%'}} />
              <Button
                type="danger"
                onClick={() => setSelfIdentImg(null)}
                style={{position: 'absolute', top: 0, right: 0}}
              >
                削除
              </Button>
            </div>) : (<Button className="upload-button" type="primary"
                               disabled={!editableKeys.includes('selfIdentificationDocumentImage')}>
              ⇧ {imgType === 'zaiLiuKa' ? '在留カード表面' : imgType === 'driversLicense' ? '運転免許証表面' : imgType === 'myNumber' ? '保険証表面' : '保険証表面'}
            </Button>)}
          </Upload>
        </Form.Item>
        <Form.Item
          className="form-item-upload"
          label={imgType === 'zaiLiuKa' ? '在留カード裏面' : imgType === 'driversLicense' ? '運転免許証裏面' : imgType === 'myNumber' ? '個人番号カード表面' : '住民票'}>
          <Upload
            beforeUpload={(file) => handleImageUpload(file, setSelfIdentImgSec)}
            listType="picture-card"
            accept="image/*"
            showUploadList={false}
            disabled={!editableKeys.includes('selfIdentificationDocumentImage2')}
          >
            {selfIdentImgSec ? (<div style={{position: 'relative'}}>
              <img src={selfIdentImgSec} alt="在留カード裏面" style={{width: '100%'}} />
              <Button
                type="danger"
                onClick={() => setSelfIdentImgSec(null)}
                style={{position: 'absolute', top: 0, right: 0}}
              >
                削除
              </Button>
            </div>) : (<Button className="upload-button" type="primary"
                               disabled={!editableKeys.includes('selfIdentificationDocumentImage2')}>
              ⇧ {imgType === 'zaiLiuKa' ? '在留カード裏面' : imgType === 'driversLicense' ? '運転免許証裏面' : imgType === 'myNumber' ? '個人番号カード表面' : '住民票'}
            </Button>)}
          </Upload>
        </Form.Item>
      </>)}

      {renderInput('口座名義', 'accountHolderName', Select, {
        onChange: handleAccountHolderNameChange, children: (<>
          <Option value="本人名義">本人名義</Option>
          <Option value="法人名義">法人名義</Option>
          <Option value="家人名義">家人名義</Option>
        </>),
      })}
      {shouldShowFamilyMemberResidenceCertificateImage() && (<Form.Item
        className="form-item-upload"
        label="本人および名義人が記載された住民票（家人名義銀行口座のみ）"
        name="familyMemberResidenceCertificateImage"
        style={{background: (editableKeys.includes('familyMemberResidenceCertificateImage') || (editableKeys.includes('accountHolderName') && accountHolderName === '家人名義')) ? 'none' : '#f0f0f0'}}
      >
        <Upload
          beforeUpload={(file) => handleImageUpload(file, setFamilyMemberResidenceCertificateImageBase64)}
          listType="picture-card"
          accept="image/*"
          showUploadList={false}
          disabled={!(editableKeys.includes('familyMemberResidenceCertificateImage') || (editableKeys.includes('accountHolderName') && accountHolderName === '家人名義')) && !urlEditableKeys.includes('familyMemberResidenceCertificateImage')}
        >
          {familyMemberResidenceCertificateImageBase64 ? (<div style={{position: 'relative'}}>
            <img src={familyMemberResidenceCertificateImageBase64} alt="本人および名義人が記載された住民票"
                 style={{width: '100%'}} />
            <Button
              type="danger"
              onClick={() => setFamilyMemberResidenceCertificateImageBase64(null)}
              style={{position: 'absolute', top: 0, right: 0}}
            >
              削除
            </Button>
          </div>) : (<Button className="upload-button" type="primary"
                             disabled={!(editableKeys.includes('familyMemberResidenceCertificateImage') || (editableKeys.includes('accountHolderName') && accountHolderName === '家人名義')) && !urlEditableKeys.includes('familyMemberResidenceCertificateImage')}>
            ⇧
          </Button>)}
        </Upload>
      </Form.Item>)}
      {renderInput('金融機関', 'financialInstitutionName')}
      {renderInput('支店名', 'branchName')}
      {renderInput('口座番号', 'accountNumber')}
      {renderInput('銀行カードまたは通帳の写真', 'bankAccountImage', Upload, {
        beforeUpload: (file) => handleImageUpload(file, setBankAccountImageBase64),
        listType: 'picture-card',
        accept: 'image/*',
        showUploadList: false,
        onChange: (info) => {
          if (info.file.status === 'done') {
            setBankAccountImageBase64(URL.createObjectURL(info.file.originFileObj));
          }
        },
        children: (<div>
          {bankAccountImageBase64 ? (<div style={{position: 'relative'}}>
            <img src={bankAccountImageBase64} alt="銀行カードまたは通帳の写真" style={{width: '100%'}} />
            <Button
              type="danger"
              onClick={() => setBankAccountImageBase64(null)}
              style={{position: 'absolute', top: 0, right: 0}}
            >
              削除
            </Button>
          </div>) : (
            <Button className="upload-button" type="primary" disabled={!editableKeys.includes('bankAccountImage')}>
              ⇧
            </Button>)}
        </div>),
      })}
      {renderInput('緊急連絡先姓名', 'emergencyContactName')}
      {renderInput('緊急連絡先電話', 'emergencyContactPhoneNumber')}
      {renderInput('緊急連絡先関係', 'emergencyContactRelationship')}
      <Form.Item className="form-item-date" label="契約日付" name="registrationDate"
                 style={{background: editableKeys.includes('registrationDate') ? 'none' : '#f0f0f0'}}>
        <DateSelect
          name="registrationDate"
          label="契約日付"
          initialValue={user.registrationDate}
          editable={editableKeys.includes('registrationDate')}
        />
      </Form.Item>

      {renderInput('顔写真', 'idPhoto', Upload, {
        beforeUpload: (file) => handleImageUpload(file, setIdPhotoBase64),
        listType: 'picture-card',
        accept: 'image/*',
        showUploadList: false,
        onChange: (info) => {
          if (info.file.status === 'done') {
            setIdPhotoBase64(URL.createObjectURL(info.file.originFileObj));
          }
        },
        children: (<div>
          {idPhotoBase64 ? (<div style={{position: 'relative'}}>
            <img src={idPhotoBase64} alt="顔写真" style={{width: '100%'}} />
            <Button
              type="danger"
              onClick={() => setIdPhotoBase64(null)}
              style={{position: 'absolute', top: 0, right: 0}}
            >
              削除
            </Button>
          </div>) : (<Button className="upload-button" type="primary" disabled={!editableKeys.includes('idPhoto')}>
            ⇧
          </Button>)}
        </div>),
      })}
      {shouldShowIdentificationDocumentImage() && (
        <Form.Item className="form-item-upload" label="日本語通称姓名記載される証明書"
                   name="identificationDocumentImage"
                   style={{background: editableKeys.includes('identificationDocumentImage') || (japaneseCommonSurname || japaneseCommonGivenName) ? 'none' : '#f0f0f0'}}>
          <Upload
            beforeUpload={(file) => handleImageUpload(file, setIdentificationDocumentImageBase64)}
            listType="picture-card"
            accept="image/*"
            showUploadList={false}
            disabled={!editableKeys.includes('identificationDocumentImage') && (!japaneseCommonSurname || !japaneseCommonGivenName)}
          >
            {identificationDocumentImageBase64 ? (<div style={{position: 'relative'}}>
              <img src={identificationDocumentImageBase64} alt="日本語通称姓名記載される証明書"
                   style={{width: '100%'}} />
              <Button
                type="danger"
                onClick={() => setIdentificationDocumentImageBase64(null)}
                style={{position: 'absolute', top: 0, right: 0}}
              >
                削除
              </Button>
            </div>) : (<Button className="upload-button" type="primary"
                               disabled={!editableKeys.includes('identificationDocumentImage') && (!japaneseCommonSurname || !japaneseCommonGivenName)}>
              アップロード 日本語通称姓名記載される証明書
            </Button>)}
          </Upload>
        </Form.Item>)}

      <div className="pdf-links">
        <div><a href="https://sign.jtmh.jp/PDFs/DMJP-ガイト用業務委託契約書.pdf"
                target="_blank" rel="noreferrer noopener">DMJP-ガイト用業務委託契約書.pdf</a></div>
        <div><a href="https://sign.jtmh.jp/PDFs/DMJP-個人情報の取扱いに関する覚書.pdf"
                target="_blank" rel="noreferrer noopener">DMJP-個人情報の取扱いに関する覚書.pdf</a></div>
        <div><a href="https://sign.jtmh.jp/PDFs/KMM-ガイト用業務委託契約書.pdf"
                target="_blank" rel="noreferrer noopener">KMM-ガイト用業務委託契約書.pdf</a></div>
        <div><a href="https://sign.jtmh.jp/PDFs/KMM-個人情報の取扱いに関する覚書.pdf"
                target="_blank" rel="noreferrer noopener">KMM-個人情報の取扱いに関する覚書.pdf</a></div>

        <h2>※署名(重要/姓名フルネームを手書きしてください、漢字またはローマ字のいずれでもかまいません。)</h2>
        <div className="signature-section">
          <Button
            className="clear-button"
            type="primary"
            danger
            ghost
            onClick={handleClearSignature}
            style={{
              width: '100%', borderRadius: '5px', height: '40px', fontSize: '16px',
            }}
            disabled={!editableKeys.includes('userSignImage')}
          >
            修正
          </Button>
          <div name="signBoxRef" className="signature-box" ref={signBoxRef} style={{width: '100%', marginTop: '10px'}}>
            <SignatureCanvas
              ref={signatureRef}
              penColor="black"
              canvasProps={{
                width: 800, // 调整宽度
                height: 300, // 调整高度
                className: 'signature-canvas',
                style: {pointerEvents: editableKeys.includes('userSignImage') ? 'auto' : 'none'},
              }}
            />
          </div>
        </div>
      </div>

      <Form.Item>
        <div style={{textAlign: 'center'}}>
          <Button className="submit-button" type="primary" htmlType="submit" style={{
            width: '100%', borderRadius: '5px', height: '40px', fontSize: '16px',
          }}>確認画面へ</Button>
        </div>
      </Form.Item>
    </Form>
    <Modal
      className="confirmation-modal"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      footer={[<Button className="confirmation-edit-button" key="edit" onClick={handleEdit}>
        修正
      </Button>, <Button className="confirmation-submit-button" key="submit" type="primary" onClick={handleOk}>
        確認して提出
      </Button>]}
    >
      {modalData && (<div className="confirmation-page">
        <Card className="confirmation-card">
          <h2 className="confirmation-title">JTMHガイド契約情報確認</h2>
          <Descriptions className="confirmation-descriptions" bordered column={1}>
            {Object.entries(modalData.userData).map(([key, value]) => (
              <Descriptions.Item label={fieldLabels[key] || key} key={key}>
                {key === 'gender' ? genderMapping[value] : value}
              </Descriptions.Item>))}
          </Descriptions>
        </Card>
      </div>)}
    </Modal>
  </div>);
};

export default EditPage;
