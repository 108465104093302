import React, {useEffect, useState} from 'react';
import {Table, Pagination, Spin, Button, Modal} from 'antd';
import {EyeOutlined} from '@ant-design/icons'; // 引入图标
import {request} from '@/utils';

const translateKeys = (key) => {
  const translations = {
    username: '用户名',
    password: '密码',
    userId: '用户ID',
    page: '页码',
    size: '页面大小',
    englishSurname: '英文姓',
    englishGivenName: '英文名',
    chineseSurname: '中文姓',
    chineseGivenName: '中文名',
    japaneseCommonSurname: '日文常用姓',
    japaneseCommonGivenName: '日文常用名',
    furiganaSurname: '姓氏发音',
    furiganaGivenName: '名字发音',
    gender: '性别',
    dateOfBirth: '出生日期',
    userEmail: '用户邮箱',
    phoneNumber: '电话号码',
    postalCode: '邮政编码',
    address: '地址',
    emergencyContactName: '紧急联系人姓名',
    emergencyContactPhoneNumber: '紧急联系电话',
    emergencyContactRelationship: '紧急联系人关系',
    accountHolderName: '账户持有人姓名',
    financialInstitutionName: '金融机构名称',
    branchName: '支行名称',
    accountNumber: '账号',
    userData: '用户数据',
  };

  return translations[key] || key; // 如果没有匹配到，返回原始键
};

const renderTranslatedParams = (paramString) => {
  if (!paramString) {
    return ''; // 如果参数为空或null，返回空字符串
  }

  try {
    const params = new URLSearchParams(paramString);
    const translatedParts = [];

    params.forEach((value, key) => {
      const translatedKey = translateKeys(key);
      translatedParts.push(<span key={key}>
          {translatedKey}: {value}{'　'}
        </span>);
    });

    return <>{translatedParts}</>; // 直接返回React组件
  } catch (e) {
    return paramString; // 如果解析失败，显示原始字符串
  }
};

const renderTranslatedText = (jsonString) => {
  try {
    const jsonObj = JSON.parse(jsonString);
    const translatedParts = [];

    Object.keys(jsonObj).forEach((key) => {
      const translatedKey = translateKeys(key);

      if (typeof jsonObj[key] === 'object' && jsonObj[key] !== null) {
        // 如果值是一个对象，递归处理
        Object.keys(jsonObj[key]).forEach((nestedKey) => {
          const translatedNestedKey = translateKeys(nestedKey);
          translatedParts.push(<span key={`${key}-${nestedKey}`}>
              {translatedNestedKey}: {jsonObj[key][nestedKey]}{'　'}
            </span>);
        });
      } else {
        translatedParts.push(<span key={key}>
            {translatedKey}: {jsonObj[key]}{'　'}
          </span>);
      }
    });

    return <>{translatedParts}</>; // 返回React元素
  } catch (e) {
    return jsonString; // 如果解析失败，显示原始字符串
  }
};

const getTranslatedURL = (url) => {
  switch (url) {
    case '/api/login':
      return '登录';
    case '/api/users/usersList/detail/edit/':
      return '管理员修改了用户信息';
    case '/api/sendVerificationCode':
      return '导游接收注册验证码';
    case '/api/users/download/':
      return '管理员下载了excel数据';
    case '/api/verifyCode':
      return '导游成功验证邮箱';
    case '/api/submit':
      return '导游成功提交签约';
    case '/api/generateAndPrint/generateContracts"':
      return '管理员一键生成所有未生成用户的契约PDF';
    case '/api/generateAndPrint/printContracts':
      return '管理员一键印刷所有未生成用户的PDF';
    case '/api/users/usersList/pdf/create/':
      return '管理员创建了单个导游的契约PDF';
    case '/api/users/usersList/pdf/dmjp-contract/':
      return '管理员下载了单个导游的DMJP契约书';
    case '/api/users/usersList/pdf/dmjp-privacy/':
      return '管理员下载了单个导游的DMJP个人情报';
    case '/api/users/usersList/pdf/kmm-contract/':
      return '管理员下载了单个导游的KMM契约书';
    case '/api/users/usersList/pdf/kmm-privacy/':
      return '管理员下载了单个导游的KMM个人情报';
    case '/api/users/usersList/delete/':
      return '管理员删除了用户';
    case '/api/users/usersList/UserInfoDownload/':
      return '管理员下载了单个导游的文字资料';
    case '/api/users/usersList/UserImageDownload/':
      return '管理员下载了单个导游的图片资料';
    default:
      return url;
  }
};

const getAdminName = (text) => {
  switch (text) {
    case 'anonymousUser':
      return '新规导游';
    case 'admin@jtmh.jp':
      return 'admin@jtmh.jp　社長';
    case 'rinbun@kamome-travel.jp':
      return 'rinbun@kamome-travel.jp　林';
    case 'tsunashima@kamome-travel.jp':
      return 'tsunashima@kamome-travel.jp　綱島';
    default:
      return text;
  }
};

const ApiLogTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [detailVisible, setDetailVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await request.get(`/logs?page=${page - 1}&size=${pageSize}`);
        setData(response.logs);
        setTotal(response.totalItems);
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const showModal = (record) => {
    setModalContent(record);
    setDetailVisible(true);
  };

  const handleDetailClose = () => {
    setDetailVisible(false);
  };

  const modalColumns = [{title: '字段', dataIndex: 'field', key: 'field', width: 150}, {
    title: '内容', dataIndex: 'content', key: 'content',
  }];

  const modalData = [{key: '1', field: 'ID', content: modalContent.id}, {
    key: '2', field: 'URL', content: getTranslatedURL(modalContent.url),
  }, {key: '3', field: 'HTTP方法', content: modalContent.httpMethod}, {
    key: '4', field: '请求参数', content: renderTranslatedParams(modalContent.requestParams),
  }, {
    key: '5', field: '请求体', content: (() => {
      try {
        // 解析 JSON 字符串
        const parsedData = JSON.parse(modalContent.requestBody);

        // 替换 password, currentPassword 和 newPassword 的值
        if (parsedData.password) {
          parsedData.password = '****';
        }

        // 将修改后的数据重新转换为 JSON 字符串并返回
        return renderTranslatedText(JSON.stringify(parsedData));
      } catch (error) {
        // 如果解析失败，返回原始文本
        return renderTranslatedText(modalContent.requestBody);
      }
    })(),
  }, {
    key: '6', field: '响应状态', content: modalContent.responseStatus,
  }, {key: '7', field: '响应时间', content: modalContent.responseTime}, {
    key: '8', field: '管理员名', content: getAdminName(modalContent.adminName),
  }, {key: '9', field: '时间戳', content: modalContent.timestamp}];

  const columns = [{
    title: 'ID', dataIndex: 'id', key: 'id', width: 50,
  }, {
    title: 'URL', dataIndex: 'url', key: 'url', width: 200, ellipsis: true, render: (text) => getTranslatedURL(text),
  }, {
    title: 'HTTPメソッド', dataIndex: 'httpMethod', key: 'httpMethod', width: 120,
  }, {
    title: 'リクエストパラメータ',
    dataIndex: 'requestParams',
    key: 'requestParams',
    width: 160,
    ellipsis: true,
    render: (text) => renderTranslatedParams(text),
  }, {
    title: 'リクエストボディ',
    dataIndex: 'requestBody',
    key: 'requestBody',
    width: 200,
    ellipsis: true,
    render: (text) => {
      try {
        // 解析 JSON 字符串
        const parsedData = JSON.parse(text);

        // 检查是否包含 password 字段并替换其值
        if (parsedData.password) {
          parsedData.password = '******';
        }

        // 将修改后的数据重新转换为 JSON 字符串并显示
        return renderTranslatedText(JSON.stringify(parsedData));
      } catch (error) {
        // 如果解析失败，返回原始文本
        return renderTranslatedText(text);
      }
    },
  }, {
    title: 'レスポンスステータス', dataIndex: 'responseStatus', key: 'responseStatus', width: 150,
  }, {
    title: 'レスポンスタイム', dataIndex: 'responseTime', key: 'responseTime', width: 150,
  }, {
    title: '管理者名',
    dataIndex: 'adminName',
    key: 'adminName',
    width: 160,
    ellipsis: true,
    render: (text) => getAdminName(text),
  }, {
    title: 'タイムスタンプ', dataIndex: 'timestamp', key: 'timestamp', width: 160,
  }, {
    title: '操作', key: 'action', width: 70, render: (_, record) => (<Button
      type="text"
      icon={<EyeOutlined />} // 使用 EyeOutlined 图标
      onClick={() => showModal(record)}
    />),
  }];

  return (<div>
    {loading ? (<Spin tip="読み込み中..." />) : (<>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={false}
        bordered
      />
      <Pagination
        current={page}
        total={total}
        pageSize={pageSize}
        onChange={setPage}
        style={{marginTop: '16px', textAlign: 'center'}}
      />
    </>)}
    <Modal
      title="詳細情報"
      open={detailVisible}
      onOk={handleDetailClose}
      onCancel={handleDetailClose}
      footer={null}
      width={800}
    >
      <Table
        columns={modalColumns}
        dataSource={modalData}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </Modal>
  </div>);
};

export default ApiLogTable;
