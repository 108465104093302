import React, { useState, useEffect } from 'react';
import { Input, Button, message, Space } from 'antd';
import { request } from '@/utils';
import './index.scss';
import { Helmet } from 'react-helmet-async';

const Email = () => {
  const [captcha, setCaptcha] = useState('');
  const [email, setEmail] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [isWeixinBrowser, setIsWeixinBrowser] = useState(false);
  let timer = null;

  const handleGetCaptcha = async () => {
    if (!email) {
      message.error('メールアドレスを入力してください');
      return;
    }
    const res = await request.post(`/sendVerificationCode?userEmail=${email}`);

    console.log(res);
    message.success('認証コードが送信されました。メールをご確認ください。');
    setCountdown(60);
  };

  useEffect(() => {
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  const handleCaptchaChange = (event) => {
    setCaptcha(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleConfirm = async () => {
    try {
      const res2 = await request.post(`/verifyCode?code=${captcha}&userEmail=${email}`);

      if (res2.message === 200 && res2.data.includes('https')) {  // 结果真确就跳转
        window.location.href = res2.data;  // 使用 window.location.href 进行跳转
      } else {
        message.error('認証コードエラーまたは有効期限切れ');
      }
    } catch (error) {
      message.error('サーバーエラーが発生しました');
    }
  };

  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase();
    setIsWeixinBrowser(ua.indexOf('micromessenger') !== -1);
  }, []);

  return (
    <div className='email-box'>
      <Helmet>
        <title>JTMH-メールページ-ガイドシステム</title>
      </Helmet>
      {isWeixinBrowser ? (
        <div className="weixin-warning">
          ブラウザでこのサイトを開いてください
        </div>
      ) : (
        <div className='email-body'>
          <h3>JTMHガイドオンライン契約</h3>
          <Input
            size="middle"
            placeholder="電子メールアドレス"
            value={email}
            onChange={handleEmailChange}
            style={{ marginBottom: 10 }}
          />

          <Space.Compact
            style={{
              width: '100%',
            }}
            size="middle"
          >
            <Input
              placeholder="認証コード"
              value={captcha}
              onChange={handleCaptchaChange}
              style={{ marginBottom: 10 }}
            />
            <Button onClick={handleGetCaptcha}
                    disabled={countdown > 0}>{countdown > 0 ? `再取得(${countdown})` : 'コード取得'}</Button>
          </Space.Compact>
          <Button size="middle" className='email-button' type='primary' onClick={handleConfirm} style={{ marginLeft: 10 }}>
            認証する
          </Button>
        </div>
      )}
    </div>
  );
};

export default Email;
