import React from 'react';
import {Modal, Button, Popconfirm, message} from 'antd';
import axios from 'axios';
import {getToken} from '@/utils';
import {PDFDocument} from 'pdf-lib';
import {PrinterOutlined, FilePdfOutlined} from '@ant-design/icons';
// import {request} from '@/utils';

const GenerateAndPrintModal = ({open, handleLinkModalClose}) => {

  const token = getToken();

  const handleGenerateContracts = async () => {
    console.log('Token:', token);  // 打印 token
    try {
      const res = await axios.post('https://sign.jtmh.jp/api/generateAndPrint/generateContracts', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        message.success(res.data.message);
      } else if (res.status === 409) {
        message.error(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error('PDFを作成できません');
    }
  };

  const getPrintContractsUserIds = async () => {
    console.log('Token:', token);  // 打印 token
    try {
      const response = await axios.get('https://sign.jtmh.jp/api/generateAndPrint/printContracts', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // 返回用户ID列表
    } catch (error) {
      console.error('Error fetching print contracts user IDs:', error);
      message.error('未下载用户ID获取失败');
      return [];
    }
  };

  // 获取PDF的字节数组
  const getPdfBytesFromBlob = async (url) => {
    const response = await axios.get(url, {
      responseType: 'blob', headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const arrayBuffer = await response.data.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  };

  const getPdfUrlFromApi = async (url, token) => {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data; // 返回PDF文件路径
  };

  const handlePrintPDFs = async () => {
    try {
      // 获取所有未下载用户的ID
      const userIds = await getPrintContractsUserIds();

      // 循环处理每个用户的PDF
      for (const userId of userIds) {
        try {
          // 获取所有PDF的URL
          const dmjpPersonPdfUrl = await getPdfUrlFromApi(`https://sign.jtmh.jp/api/users/usersList/pdf/dmjp-privacy/?userId=${userId}`, token);
          const dmjpContractPdfUrl = await getPdfUrlFromApi(`https://sign.jtmh.jp/api/users/usersList/pdf/dmjp-contract/?userId=${userId}`, token);
          const kmmPersonPdfUrl = await getPdfUrlFromApi(`https://sign.jtmh.jp/api/users/usersList/pdf/kmm-privacy/?userId=${userId}`, token);
          const kmmContractPdfUrl = await getPdfUrlFromApi(`https://sign.jtmh.jp/api/users/usersList/pdf/kmm-contract/?userId=${userId}`, token);

          // 获取所有PDF的字节数组
          const infoPdfBytes = await getPdfBytesFromBlob(`https://sign.jtmh.jp/api/users/usersList/UserInfoDownload/?userId=${userId}`, token);
          const imgPdfBytes = await getPdfBytesFromBlob(`https://sign.jtmh.jp/api/users/usersList/UserImageDownload/?userId=${userId}`, token);
          const dmjpPersonPdfBytes = await getPdfBytesFromBlob(dmjpPersonPdfUrl, token);
          const dmjpContractPdfBytes = await getPdfBytesFromBlob(dmjpContractPdfUrl, token);
          const kmmPersonPdfBytes = await getPdfBytesFromBlob(kmmPersonPdfUrl, token);
          const kmmContractPdfBytes = await getPdfBytesFromBlob(kmmContractPdfUrl, token);

          // 创建一个新的PDF文档
          const mergedPdf = await PDFDocument.create();

          // 将每个PDF添加到新的PDF文档中
          const pdfsToMerge = [infoPdfBytes, imgPdfBytes, dmjpPersonPdfBytes, dmjpContractPdfBytes, kmmPersonPdfBytes, kmmContractPdfBytes];

          for (const pdfBytes of pdfsToMerge) {
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
          }

          // 保存合并后的PDF
          const mergedPdfBytes = await mergedPdf.save();

          // 触发文件下载
          const blob = new Blob([mergedPdfBytes], {type: 'application/pdf'});
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${userId}-ガイド一括情報.pdf`; // 使用userId作为文件名的一部分
          a.click();
          URL.revokeObjectURL(url);

          message.success(`一括PDFがダウンロードされました (userId: ${userId})`);

          // 调用接口将下载状态设置为true
          try {
            const response = await fetch(`https://sign.jtmh.jp/api/users/usersList/isDownload?userId=${userId}`, {
              method: 'POST', headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.ok) {
              console.log(`ダウンロードステータスが更新されました (userId: ${userId})`);
              message.success(`ダウンロードステータスが更新されました (userId: ${userId})`);
            } else {
              console.error(`ダウンロードステータスの更新中にエラーが発生しました (userId: ${userId})`);
              message.error(`ダウンロードステータスの更新中にエラーが発生しました (userId: ${userId})`);
            }
          } catch (error) {
            console.error(`要求に失敗しました (userId: ${userId}):`, error);
            message.error(`要求に失敗しました (userId: ${userId})`);
          }
        } catch (error) {
          console.error(`PDFのマージまたはダウンロード中にエラーが発生しました (userId: ${userId}):`, error);
          message.error(`一括PDFマージまたはダウンロード中にエラーが発生しました (userId: ${userId})`);
        }
      }
    } catch (error) {
      console.error('未下载用户ID获取失败:', error);
      message.error('未下载用户ID获取失败');
    }
  };

  return (<Modal
    title="未一括印刷ガイド操作"
    open={open}
    onOk={handleLinkModalClose}
    onCancel={handleLinkModalClose}
    footer={[<Popconfirm
      title="一括印刷"
      description={<>すべてのPDFをダウンロードしますか？<br />一括印刷する前に必ず「契約書作成」をしてください。</>}
      onConfirm={handlePrintPDFs}
      okText="一括印刷"
      cancelText="取り消し"
    ><p>數據量極大請耐心等待。<br />請完成“一鍵全部生成契約書”後再“一鍵全部印刷PDF”</p>
      <Button key="generate" type="primary" style={{marginRight: '20px'}} icon={<PrinterOutlined />}>
        一鍵全部印刷PDF
      </Button>
    </Popconfirm>, <Popconfirm
      title="契約書作成"
      description={<>契約書は一度しか作成できません。<br />全てのガイド情報が正確であることを確認してください。<br />契約書と個人情報を作成しますか？<br />（誤って作成した場合は開発者にご連絡してください）</>}
      onConfirm={handleGenerateContracts}
      okText="契約書作成"
      cancelText="取り消し"
    ><Button key="print" type="primary" style={{marginRight: '20px'}} icon={<FilePdfOutlined />}>
      一鍵全部生成契約書
    </Button>
    </Popconfirm>]}
  >
    {/* 可以在这里添加额外的内容，如果需要的话 */}
  </Modal>);
};

export default GenerateAndPrintModal;
