import React, {useEffect, useState} from 'react';

const VersionChecker = () => {
  const [currentVersion, setCurrentVersion] = useState(null);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch(`/version.txt?timestamp=${new Date().getTime()}`);
        const latestVersion = await response.text();

        if (currentVersion && currentVersion !== latestVersion) {
          const confirmed = window.confirm('检测到新版本，请刷新页面以获取最新内容。');
          if (confirmed) {
            // 强制刷新页面
            window.location.reload(true);
          }
        }
        setCurrentVersion(latestVersion);
      } catch (error) {
        console.error('无法获取版本信息:', error);
      }
    };

    // 立即检查版本
    checkVersion();

    // 设置定期轮询，检查版本是否更新
    const interval = setInterval(checkVersion, 5000); // 每秒检查一次

    return () => clearInterval(interval); // 清除定时器
  }, [currentVersion]);

  return null;
};

export default VersionChecker;
