import React from 'react';
import ApiLogTable from './components/ApiLogTable';

function App() {
  return (<div className="App">
      <h2>データ ログ</h2>
      <ApiLogTable />
    </div>);
}

export default App;
