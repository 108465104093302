import React, {useEffect, useState} from 'react';
import {EditTwoTone, DeleteTwoTone, EyeTwoTone, DownOutlined} from '@ant-design/icons';
import {SearchOutlined, MailOutlined, DeleteOutlined, FileExcelOutlined, PrinterOutlined} from '@ant-design/icons';

import {
  Table,
  Modal,
  Input,
  Popconfirm,
  Form,
  Button,
  Space,
  message,
  Descriptions,
  Radio,
  Select,
  Upload,
  Dropdown,
  Checkbox,
  Menu,
} from 'antd';

import {request} from '@/utils';
import qs from 'qs';
import axios from 'axios';
import {saveAs} from 'file-saver';
import SecureImageDisplay from './SecureImageDisplay';
import {getToken} from '@/utils';
import {PDFDocument} from 'pdf-lib';
import GenerateAndPrintModal from '@/pages/Home/GenerateAndPrintModal';

const {Option} = Select;

// 返回表格查询需要的参数格式
const getRandomUserParams = (params) => ({
  size: params.pagination?.pageSize, page: params.pagination?.current - 1,
});
const App = () => {
  const token = getToken();
  // 表格参数
  const columns = [{
    title: '番号', // render: (name) => `${name.first} ${name.last}`,
    render: (_, record) => `${record.userId}`,
  }, {
    title: '本国姓名', // render: (name) => `${name.first} ${name.last}`,
    render: (_, record) => `${record.chineseSurname} ${record.chineseGivenName}`,
  }, {
    title: '日本姓名', // render: (name) => `${name.first} ${name.last}`,
    render: (_, record) => `${record.japaneseCommonSurname} ${record.japaneseCommonGivenName}`,
  }, {
    title: 'フリガナ', // render: (name) => `${name.first} ${name.last}`,
    render: (_, record) => `${record.furiganaSurname} ${record.furiganaGivenName}`,
  }, {
    title: 'ローマ姓名', // render: (name) => `${name.first} ${name.last}`,
    render: (_, record) => `${record.englishSurname} ${record.englishGivenName}`,
  }, {
    title: '性別', dataIndex: 'gender', render: (text) => {
      return text === 'male' ? '男' : '女';
    },
  }, {
    title: '電話', dataIndex: 'phoneNumber',
  }, {
    title: 'e-mail', dataIndex: 'userEmail',
  }, {
    title: '契約日付', dataIndex: 'registrationDate',
  }, {
    title: '一括印刷状態', dataIndex: 'download', render: (download) => (download ? <span style={{
      backgroundColor: 'green', color: 'white', padding: '2px 4px', borderRadius: '4px',
    }}>一括印刷完了</span> : <span
      style={{backgroundColor: 'red', color: 'white', padding: '2px 4px', borderRadius: '4px'}}>未一括印刷✖</span>),
  }, {
    title: (<div>
      操作</div>), fixed: 'right', render: (_, record) => {
      return (<Space size="middle">
        <Button icon={<EyeTwoTone />} onClick={() => handleDetailShow(record)} />
        <Button icon={<EditTwoTone />} onClick={() => showDetailModal(record)} />
        <Popconfirm
          title="削除"
          description="削除しますか？"
          onConfirm={() => deleteItems([record.userId])}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<DeleteTwoTone />}
          />
        </Popconfirm>
      </Space>);
    },
  }];
  const [data, setData] = useState([{
    'userId': 34,
    'chineseSurname': '赵',
    'chineseGivenName': '伟',
    'furiganaSurname': 'カトウ',
    'furiganaGivenName': 'タカヒロ',
    'englishSurname': 'Zhao',
    'englishGivenName': 'Wei',
    'japaneseCommonSurname': '加藤',
    'japaneseCommonGivenName': '貴博',
    'gender': 'male',
    'identificationDocumentImage': '/path/to/doc14.jpg',
    'selfIdentificationDocumentImage': '/path/to/selfdoc14.jpg',
    'selfIdentificationDocumentImage2': null,
    'bankAccountImage': '/path/to/bank14.jpg',
    'accountHolderName': '本人名義',
    'familyMemberResidenceCertificateImage': null,
    'financialInstitutionName': '东京银行',
    'branchName': '渋谷支店',
    'accountNumber': '234567890',
    'userEmail': 'wei.zhao@example.com',
    'phoneNumber': '09890123456',
    'postalCode': '150-0002',
    'address': '东京都渋谷区渋谷1-2-3',
    'emergencyContactName': '周建国',
    'emergencyContactPhoneNumber': '08012345678',
    'emergencyContactRelationship': '同事',
    'registrationDate': '2024-04-23T13:12:18',
    'userSignImage': '/path/to/sign14.jpg',
    'deleted': false,
  }]);

  const [dataRecord, setdataRecord] = useState({});
  // 传值表单
  const [form] = Form.useForm();
  //性别
  const genderMap = {
    male: '男', female: '女', // 其他性别选项
  };
  // 列表配置
  const items = [{
    key: '1',
    label: '本国姓名',
    name: ['chineseSurname', 'chineseGivenName'],
    children: dataRecord.chineseSurname + '　' + dataRecord.chineseGivenName,
  }, {
    key: '2',
    label: 'ローマ姓名',
    name: ['englishSurname', 'englishGivenName'],
    children: dataRecord.englishSurname + '　' + dataRecord.englishGivenName,
  }, {
    key: '3',
    label: '日本姓名',
    name: ['japaneseCommonSurname', 'japaneseCommonGivenName'],
    children: dataRecord.japaneseCommonSurname + '　' + dataRecord.japaneseCommonGivenName,
  }, {
    key: '4',
    label: 'フリガナ',
    name: ['furiganaSurname', 'furiganaGivenName'],
    children: dataRecord.furiganaSurname + '　' + dataRecord.furiganaGivenName,
  }, {
    key: '5', label: '性别', name: 'gender', children: genderMap[dataRecord.gender] || dataRecord.gender,
  }, {
    key: '6', label: '生年月日', name: 'dateOfBirth', children: dataRecord.dateOfBirth,
  }, {
    key: '7', label: 'メールアドレス', name: 'userEmail', children: dataRecord.userEmail,
  }, {
    key: '8', label: '電話番号', name: 'phoneNumber', children: dataRecord.phoneNumber,
  }, {
    key: '9', label: '郵便番号', name: 'postalCode', children: dataRecord.postalCode,
  }, {
    key: '10', label: '住所', name: 'address', children: dataRecord.address,
  }, {
    key: '11', label: '緊急連絡先名', name: 'emergencyContactName', children: dataRecord.emergencyContactName,
  }, {
    key: '12',
    label: '緊急連絡先電話',
    name: 'emergencyContactPhoneNumber',
    children: dataRecord.emergencyContactPhoneNumber,
  }, {
    key: '13',
    label: '緊急連絡先関係',
    name: 'emergencyContactRelationship',
    children: dataRecord.emergencyContactRelationship,
  }, {
    key: '14', label: '口座名義', name: 'accountHolderName', children: dataRecord.accountHolderName,
  }, {
    key: '15', label: '金融機関名', name: 'financialInstitutionName', children: dataRecord.financialInstitutionName,
  }, {
    key: '16', label: '支店', name: 'branchName', children: dataRecord.branchName,
  }, {
    key: '17', label: '口座番号', name: 'accountNumber', children: dataRecord.accountNumber,
  }, {
    key: '18',
    label: '個人身分証明一',
    name: 'selfIdentificationDocumentImage',
    children: <div className="image-container">

      <SecureImageDisplay imageUrl={`${dataRecord.selfIdentificationDocumentImage}?t=${new Date().getTime()}`}
                          token={token} />
    </div>,
  }, {
    key: '19',
    label: '個人身分証明二',
    name: 'selfIdentificationDocumentImage2',
    children: <div className="image-container">

      <SecureImageDisplay imageUrl={`${dataRecord.selfIdentificationDocumentImage2}?t=${new Date().getTime()}`}
                          token={token} />
    </div>,
  }, {
    key: '20', label: '銀行口座情報', name: 'bankAccountImage', children: <div className="image-container">

      <SecureImageDisplay imageUrl={`${dataRecord.bankAccountImage}?t=${new Date().getTime()}`} token={token} />
    </div>,
  }, {
    key: '21', label: '顔写真', name: 'idPhoto', children: <div className="image-container">

      <SecureImageDisplay imageUrl={`${dataRecord.idPhoto}?t=${new Date().getTime()}`} token={token} />
    </div>,
  }, {
    key: '22', label: '日本姓名証明書', name: 'identificationDocumentImage', children: <div className="image-container">

      <SecureImageDisplay imageUrl={`${dataRecord.identificationDocumentImage}?t=${new Date().getTime()}`}
                          token={token} />
    </div>,
  }, {
    key: '23',
    label: '家族名義の住民票',
    name: 'familyMemberResidenceCertificateImage',
    children: <div className="image-container">

      <SecureImageDisplay imageUrl={`${dataRecord.familyMemberResidenceCertificateImage}?t=${new Date().getTime()}`}
                          token={token} />
    </div>,
  }, {
    key: '24', label: '契約日付', name: 'registrationDate', children: dataRecord.registrationDate,
  }];

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1, pageSize: 10,
    }, isSearch: false,
  });
  // 头部功能
  const [guidName, setGuidName] = useState(null);
  const [guidPhone, setGuidPhone] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [linkAddress, setLinkAddress] = useState('abcdtest.com');

  // 详细模块可见状态
  const [detailVisible, setDetailVisible] = useState(false);

  // 链接模块可见状态
  const [linkModaVisible, setlinkModaVisible] = useState(false);

  // 获取链接模块可见状态
  const [editVisible, setEditVisible] = useState(false);
  // 多选功能，selectedRows 是一个状态，用于存储当前选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [selfIdentimg, setSelfIdentimg] = useState(null);
  const [selfIdentimgsec, setSelfIdentimgsec] = useState(null);
  const [bankAccountImageBase64, setbankAccountImageBase64] = useState(null);
  const [familyMemberResidenceCertificateImageBase64, setFamilyMemberResidenceCertificateImageBase64] = useState(null);
  const [identificationDocumentImageBase64, setIdentificationDocumentImageBase64] = useState(null);

  let payload;
  // 删除
  const deleteItems = async (data) => {

    let userIds;
    if (data.length > 0) {
      userIds = data.join(',');
    } else {
      userIds = selectedRowKeys.join(',');
    }

    const result = await request.delete('/users/usersList/delete/?userIds=' + userIds);

    setSelectedRowKeys([]);

    if (result.message === 200) {
      message.success(result.count + `件削除しました`);

    } else if (result.message === 404) {
      message.error(`この件はありません`);
    } else if (result.message === 400) {
      message.error(`削除の件を選んでください`);
    }

    setTableParams({
      ...tableParams, pagination: {
        ...tableParams.pagination, a: new Date().toLocaleString(),
      },
    });
  };
  // 下载excel
  const excelDownload = async () => {

    const userIds = selectedRowKeys;
    try {
      request.post('/users/download/', {userIds}, {
        // 设置responseType对象格式为blob
        responseType: 'blob',
      }).then(res => {
        // 创建下载的链接
        const url = window.URL.createObjectURL(new Blob([res], // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
        const link = document.createElement('a');
        link.href = url;
        // 从header中获取服务端命名的文件名
        const fileName = '1213';
        link.setAttribute('download', 'guide.xlsx');
        document.body.appendChild(link);
        link.click();
        message.success('発行成功');
      });

    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };
  //搜索按钮
  const handleButtonClick = async () => {

    if (guidName && guidPhone) {
      message.info('入力できる検索条件は 1 つだけです');
    } else {
      setTableParams({
        pagination: {
          current: 1, pageSize: 10,
        }, isSearch: true,
      });
    }
  };
  const emailButtonClick = async () => {
    console.log(emailAddress);

    async function getLink() {
      let res;
      if (emailAddress !== null) {
        res = await request.post('/generate-url', emailAddress);
      }
      if (res !== null) {
        console.log(res);
        setLinkAddress(res); // 设置包含url和message的对象
        showLinkModal();
      }
    }

    await getLink();
  };


  // 获取主页数据
  useEffect(() => {

    async function getList() {
      setLoading(true);

      let res;
      if (!tableParams.isSearch) {
        res = await request.get('/users/usersList?' + qs.stringify(getRandomUserParams(tableParams)));
      } else {

        if (guidName && !guidPhone) {
          res = await request.get(`/users/searchName/?keyword=${guidName}&` + qs.stringify(getRandomUserParams(tableParams)));
          setData(res.users);
        } else if (!guidName && guidPhone) {
          res = await request.get(`/users/searchPhoneNumber/?keyword=${guidPhone}&` + qs.stringify(getRandomUserParams(tableParams)));
          setData(res.users);
        } else {
          res = await request.get('/users/usersList?' + qs.stringify(getRandomUserParams(tableParams)));
        }
      }

      if (res !== null) {
        setTableParams({
          ...tableParams, pagination: {
            ...tableParams.pagination, total: res?.totalItems, // 200 is mock data, you should read it from server
          },
        });
        setData(res.users);
      }
      setLoading(false);
    }

    getList();
    // }, [tableParams]);
  }, [JSON.stringify(tableParams.pagination)]);

  // 表格参数变化
  const handleTableChange = (pagination) => {
    setTableParams({
      ...tableParams, pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  // 点击详细按钮
  const handleDetailShow = (record) => {

    // 设置列表值
    setdataRecord(record);
    // 设置编辑表单值
    form.setFieldsValue({
      ...record,
      selfIdentificationDocumentImaged: null,
      selfIdentificationDocumentImageSecd: null,
      bankDocumentImageSecd: null,
      familyselfIdentificationDocumentImageSecd: null,
      japNameselfIdentificationDocumentImageSecd: null,

    });
    setDetailVisible(true);
  };

  // 检索条件输入
  const guidNameInputChange = (e) => {
    setGuidName(e.target.value.replace(/(^\s*)|(\s*$)/g, ''));
  };
  const guidPhoneInputChange = (e) => {
    const cleanedValue = e.target.value.replace(/(^\s*)|(\s*$)/g, '').replace(/\D/g, '');

    let formattedPhoneNumber = cleanedValue;

    // 根据输入的长度来决定插入破折号的位置
    if (cleanedValue.length > 3 && cleanedValue.length <= 7) {
      formattedPhoneNumber = cleanedValue.replace(/^(\d{3})(\d{1,4})$/, '$1-$2');
    } else if (cleanedValue.length > 7) {
      formattedPhoneNumber = cleanedValue.replace(/^(\d{3})(\d{4})(\d{0,4})$/, '$1-$2-$3');
    }

    setGuidPhone(formattedPhoneNumber);
  };

  const emailInputChange = (e) => {
    setEmailAddress({email: e.target.value.replace(/(^\s*)|(\s*$)/g, '')});
  };

  const showLinkModal = () => {
    setlinkModaVisible(true);
  };

  const showDetailModal = (record) => {
    // 设置列表值
    setdataRecord(record);
    // 设置编辑表单值
    form.setFieldsValue({
      ...record,
      selfIdentificationDocumentImaged: null,
      selfIdentificationDocumentImageSecd: null,
      bankDocumentImageSecd: null,
      familyselfIdentificationDocumentImageSecd: null,
      japNameselfIdentificationDocumentImageSecd: null,

    });
    setEditVisible(true);
  };

  // 获取PDF的字节数组
  const getPdfBytesFromBlob = async (url) => {
    const response = await axios.get(url, {
      responseType: 'blob', headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const arrayBuffer = await response.data.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  };

  const allPdfGet = async () => {
    try {
      // 获取所有PDF的字节数组
      const infoPdfBytes = await getPdfBytesFromBlob(`https://sign.jtmh.jp/api/users/usersList/UserInfoDownload/?userId=${dataRecord.userId}`);
      const imgPdfBytes = await getPdfBytesFromBlob(`https://sign.jtmh.jp/api/users/usersList/UserImageDownload/?userId=${dataRecord.userId}`);
      const dmjpPersonPdfBytes = await getPdfBytesFromBlob((await request.get(`/users/usersList/pdf/dmjp-privacy/?userId=${dataRecord.userId}`)).data);
      const dmjpContractPdfBytes = await getPdfBytesFromBlob((await request.get(`/users/usersList/pdf/dmjp-contract/?userId=${dataRecord.userId}`)).data);
      const kmmPersonPdfBytes = await getPdfBytesFromBlob((await request.get(`/users/usersList/pdf/kmm-privacy/?userId=${dataRecord.userId}`)).data);
      const kmmContractPdfBytes = await getPdfBytesFromBlob((await request.get(`/users/usersList/pdf/kmm-contract/?userId=${dataRecord.userId}`)).data);

      // 创建一个新的PDF文档
      const mergedPdf = await PDFDocument.create();

      // 将每个PDF添加到新的PDF文档中
      const pdfsToMerge = [infoPdfBytes, imgPdfBytes, dmjpPersonPdfBytes, dmjpContractPdfBytes, kmmPersonPdfBytes, kmmContractPdfBytes];

      for (const pdfBytes of pdfsToMerge) {
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      // 保存合并后的PDF
      const mergedPdfBytes = await mergedPdf.save();

      // 触发文件下载
      const blob = new Blob([mergedPdfBytes], {type: 'application/pdf'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${dataRecord.englishSurname}_${dataRecord.englishGivenName}-ガイド一括情報.pdf`;
      a.click();
      URL.revokeObjectURL(url);

      message.success('一括PDFがダウンロードされました');

      // 调用接口将下载状态设置为true
      try {
        const response = await fetch(`https://sign.jtmh.jp/api/users/usersList/isDownload?userId=${dataRecord.userId}`, {
          method: 'POST', headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          console.log('ダウンロードステータスが更新されました');
          message.success('ダウンロードステータスが更新されました');
        } else {
          console.error('ダウンロードステータスの更新中にエラーが発生しました');
          message.error('ダウンロードステータスの更新中にエラーが発生しました');
        }
      } catch (error) {
        console.error('要求に失敗しました:', error);
        message.error('要求に失敗しました');
      }

    } catch (error) {
      console.error('PDFのマージまたはダウンロード中にエラーが発生しました:', error);
      message.error('一括PDFマージまたはダウンロード中にエラーが発生しました');
    }
  };

  const printInfoPdf = async () => {
    try {
      const response = await axios.get(`https://sign.jtmh.jp/api/users/usersList/UserInfoDownload/?userId=${dataRecord.userId}`, {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data], {type: 'application/pdf'});
      saveAs(blob, `${dataRecord.englishSurname}_${dataRecord.englishGivenName}-ガイド詳細文字情報`);
      message.success('文字情報PDFがダウンロードされました');
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      message.error('文字情報PDFのダウンロードに失敗しました');
    }
  };
  const printImgPdf = async () => {
    try {
      const response = await axios.get(`https://sign.jtmh.jp/api/users/usersList/UserImageDownload/?userId=${dataRecord.userId}`, {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data], {type: 'application/pdf'});
      saveAs(blob, `${dataRecord.englishSurname}_${dataRecord.englishGivenName}-ガイド詳細画像情報`);
      message.success('画像情報PDFがダウンロードされました');
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      message.error('画像情報PDFのダウンロードに失敗しました');
    }
  };
  const toPDF = async () => {
    const res = await request.get(`/users/usersList/pdf/create/?userId=${dataRecord.userId}`);
    if (res.message === 200) {
      message.success('PDFを作成完了');
    } else if (res.message === 409) {
      message.error('PDFもうあります');
    } else {
      message.error('PDFを作成できません');
    }
  };

  const dmjpPersonPdfGet = async () => {
    try {
      const res = await request.get(`/users/usersList/pdf/dmjp-privacy/?userId=${dataRecord.userId}`);
      const response = await axios.get(res.data, {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data], {type: 'application/pdf'});
      saveAs(blob, `${dataRecord.englishSurname}_${dataRecord.englishGivenName}-DMJP個人情報`);
      message.success('DMJP個人情報PDFがダウンロードされました');
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      message.error('DMJP個人情報PDFのダウンロードに失敗しました');
    }
  };
  const dmjpContractPdfGet = async () => {
    try {
      const res = await request.get(`/users/usersList/pdf/dmjp-contract/?userId=${dataRecord.userId}`);
      const response = await axios.get(res.data, {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data], {type: 'application/pdf'});
      saveAs(blob, `${dataRecord.englishSurname}_${dataRecord.englishGivenName}-DMJP契約書`);
      message.success('DMJP契約書PDFがダウンロードされました');
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      message.error('DMJP契約書PDFのダウンロードに失敗しました');
    }
  };
  const kmmPersonPdfGet = async () => {
    try {
      const res = await request.get(`/users/usersList/pdf/kmm-privacy/?userId=${dataRecord.userId}`);
      const response = await axios.get(res.data, {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data], {type: 'application/pdf'});
      saveAs(blob, `${dataRecord.englishSurname}_${dataRecord.englishGivenName}-KMM個人情報`);
      message.success('KMM個人情報PDFがダウンロードされました');
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      message.error('KMM個人情報PDFのダウンロードに失敗しました');
    }
  };
  const kmmContractPdfGet = async () => {
    try {
      const res = await request.get(`/users/usersList/pdf/kmm-contract/?userId=${dataRecord.userId}`);
      const response = await axios.get(res.data, {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data], {type: 'application/pdf'});
      saveAs(blob, `${dataRecord.englishSurname}_${dataRecord.englishGivenName}-KMM契約書`);
      message.success('KMM契約書PDFがダウンロードされました');
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      message.error('KMM契約書PDFのダウンロードに失敗しました');
    }
  };

  //得到一个下拉菜单，点击大按钮时，可以选择不同的操作↓
  const handleMenuClick = async (action) => {
    setLoading(true);
    try {
      await action(); // 执行传入的函数
    } finally {
      setLoading(false);
    }
  };

  const menu = (<Menu>
    <Menu.Item key="1" onClick={() => handleMenuClick(printInfoPdf)}>文字情報印刷</Menu.Item>
    <Menu.Item key="2" onClick={() => handleMenuClick(printImgPdf)}>画像情報印刷</Menu.Item>
    <Menu.Item key="3" onClick={() => handleMenuClick(dmjpPersonPdfGet)}>DMJP個人情報</Menu.Item>
    <Menu.Item key="4" onClick={() => handleMenuClick(dmjpContractPdfGet)}>DMJP契約書</Menu.Item>
    <Menu.Item key="5" onClick={() => handleMenuClick(kmmPersonPdfGet)}>KMM個人情報</Menu.Item>
    <Menu.Item key="6" onClick={() => handleMenuClick(kmmContractPdfGet)}>KMM契約書</Menu.Item>
  </Menu>);

  //得到一个下拉菜单，点击大按钮时，可以选择不同的操作↑

  const handleLinkModalClose = () => {
    setlinkModaVisible(false);
  };
  const handleDetailClose = () => {
    setDetailVisible(false); // 关闭确认弹窗

  };
  const handleEditClose = () => {
    setEditVisible(false);
    // 设置编辑表单值
    setSelfIdentimg(null);
    setSelfIdentimgsec(null);
    setbankAccountImageBase64(null);
    setFamilyMemberResidenceCertificateImageBase64(null);
    setIdentificationDocumentImageBase64(null);

    form.setFieldsValue({
      ...dataRecord,
      selfIdentificationDocumentImaged: null,
      selfIdentificationDocumentImageSecd: null,
      bankDocumentImageSecd: null,
      familyselfIdentificationDocumentImageSecd: null,
      japNameselfIdentificationDocumentImageSecd: null,

    });
  };
  // 确认修改
  const handleEditOK = () => {

    form.validateFields().then(async (values) => {

      // 构造要发送的数据
      delete values.selfIdentificationDocumentImaged;
      delete values.selfIdentificationDocumentImageSecd;
      delete values.bankDocumentImageSecd;
      delete values.familyselfIdentificationDocumentImageSecd;
      delete values.japNameselfIdentificationDocumentImageSecd;
      console.log(values);

      payload = {
        userId: dataRecord.userId, // userId: 100,

        userData: values, // 其他字段按需添加
      };

      // 条件检查后添加
      if (selfIdentimg) {
        payload.selfIdentificationDocumentImageBase64 = selfIdentimg;
      }

      if (selfIdentimgsec) {
        payload.selfIdentificationDocumentImage2Base64 = selfIdentimgsec;
      }

      if (bankAccountImageBase64) {
        payload.bankAccountImageBase64 = bankAccountImageBase64;
      }

      if (familyMemberResidenceCertificateImageBase64) {
        payload.familyMemberResidenceCertificateImageBase64 = familyMemberResidenceCertificateImageBase64;
      }

      if (identificationDocumentImageBase64) {
        payload.identificationDocumentImageBase64 = identificationDocumentImageBase64;
      }

      const editResult = await request.post(`/users/usersList/detail/edit/?userId=${dataRecord.userId}`, payload);

      // 刷新页面
      console.log(editResult);
      if (editResult.statusCode === 200) {
        message.success('修正しました');
        setTableParams({
          ...tableParams, pagination: {
            ...tableParams.pagination, a: new Date().toLocaleString(),
          },
        });
      } else {
        message.error('修正できません');
      }

    });
    setEditVisible(false);
    setDetailVisible(false);

  };

  // 通用文件上传处理函数
  const handleImageUpload = (e, setImageBase64, imageType) => {
    if (!Array.isArray(e) && e.fileList[0]) {
      const file = e.file;
      const reader = new FileReader();

      reader.onload = async () => {
        const base64String = reader.result;
        setImageBase64(base64String);

        // 上传图片到后端
        const response = await fetch('/api/upload', {
          method: 'POST', headers: {
            'Content-Type': 'application/json',
          }, body: JSON.stringify({base64Image: base64String, imageType}),  // 传递图片和类型
        });

        const data = await response.json();

        if (data.message === 200) {
          message.success(data.data);
        } else {
          message.error(data.data);
        }
      };

      reader.readAsDataURL(file);
    } else {
      setImageBase64(null);
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const idImgFirst = (e) => {
    console.log('Uploading first ID image:', e);
    return handleImageUpload(e, setSelfIdentimg, 'idFirst');
  };

  const idImgSec = (e) => {
    console.log('Uploading second ID image:', e);
    return handleImageUpload(e, setSelfIdentimgsec, 'idSec');
  };

  const bankImgSec = (e) => {
    console.log('Uploading bank image:', e);
    return handleImageUpload(e, setbankAccountImageBase64, 'bank');
  };

  const familyImgSec = (e) => {
    console.log('Uploading family image:', e);
    return handleImageUpload(e, setFamilyMemberResidenceCertificateImageBase64, 'family');
  };

  const japNameImgSec = (e) => {
    console.log('Uploading Japanese name image:', e);
    return handleImageUpload(e, setIdentificationDocumentImageBase64, 'japName');
  };

  // 生成年份、月份、日期的选项↓
  const years = [];
  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    years.push(<Option key={i} value={i}>{i}</Option>);
  }

  const months = [];
  for (let i = 1; i <= 12; i++) {
    const month = String(i).padStart(2, '0');
    months.push(<Option key={month} value={month}>{month}</Option>);
  }

  const days = [];
  for (let i = 1; i <= 31; i++) {
    const day = String(i).padStart(2, '0');
    days.push(<Option key={day} value={day}>{day}</Option>);
  }

  const DateOfBirthPicker = ({value, onChange}) => {
    const [year, setYear] = useState();
    const [month, setMonth] = useState();
    const [day, setDay] = useState();

    useEffect(() => {
      if (value) {
        const [y, m, d] = value.split('-');
        setYear(parseInt(y, 10));
        setMonth(parseInt(m, 10));
        setDay(parseInt(d, 10));
      }
    }, [value]);

    useEffect(() => {
      if (year && month && day) {
        onChange(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
      }
    }, [year, month, day, onChange]);

    return (<div style={{display: 'flex', justifyContent: 'space-between', width: '300px'}}>
      <Select value={year} onChange={setYear} placeholder="年" style={{width: '33%'}}>
        {years}
      </Select>
      <Select value={month} onChange={setMonth} placeholder="月" style={{width: '33%'}}>
        {months}
      </Select>
      <Select value={day} onChange={setDay} placeholder="日" style={{width: '33%'}}>
        {days}
      </Select>
    </div>);
  };
  // 生成年份、月份、日期的选项↑

  // 让用户重新修改信息↓
  const [visible, setVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [remarks, setRemarks] = useState('');
  const [confirmVisible, setConfirmVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (selectedKeys.length === 0) {
      message.warning('请先选择数据');
      return;
    }
    setConfirmVisible(true);
  };

  const handleSend = async () => {
    const selectedNames = selectedKeys.map(key => {
      const item = items.find(i => i.key === key);
      return item.name instanceof Array ? item.name : [item.name];
    });

    try {
      await axios.post('https://sign.jtmh.jp/api/users/usersList/editByUser', {
        keys: selectedNames, remarks, userId: dataRecord.userId, userEmail: dataRecord.userEmail,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('数据已发送');
      // setSelectedKeys([]);
      // setRemarks('');
      resetState();
      setVisible(false);
      setConfirmVisible(false);
    } catch (error) {
      console.error('Error sending selected keys:', error);
      message.error('发送失败');
    }
  };

  const handleCancel = () => {
    setVisible(false);
    resetState();
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
  };

  const handleCheckboxChange = (key) => {
    setSelectedKeys(prevSelectedKeys => prevSelectedKeys.includes(key) ? prevSelectedKeys.filter(k => k !== key) : [...prevSelectedKeys, key]);
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };
  //重置相关的状态
  const resetState = () => {
    setSelectedKeys([]);
    setRemarks('');
  };
  // 让用户重新修改信息↑

  //一键生成和下载↓
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLinkModal2Open = () => {
    setIsModalOpen(true);
  };

  const handleLinkModal2Close = () => {
    setIsModalOpen(false);
  };
  //一键生成和下载↑

  return (<div>
    <div style={{marginBottom: 16}}>
      <span>姓名検索　</span>
      <Input
        placeholder="藤井"
        onChange={guidNameInputChange}
        style={{
          width: '10%', marginRight: '20px',
        }}
      />
      <span>電話番号　</span>
      <Input
        placeholder="080-1234-5678"
        onChange={guidPhoneInputChange}
        style={{
          width: '15%', marginRight: '20px',
        }}
      />
      <Button
        onClick={handleButtonClick}
        style={{
          marginRight: '30px',
        }}
        type="primary"
        icon={<SearchOutlined />}
      >
        検索
      </Button>
      {/*<span>ガイドさんメール </span>*/}
      {/*<Input*/}
      {/*  placeholder="xxx@gmail.com"*/}
      {/*  onChange={emailInputChange}*/}
      {/*  style={{*/}
      {/*    width: '15%', marginRight: '20px',*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<Button*/}
      {/*  onClick={emailButtonClick}*/}
      {/*  style={{*/}
      {/*    marginRight: '20px',*/}
      {/*  }}*/}
      {/*  type="primary"*/}
      {/*  icon={<MailOutlined />}*/}
      {/*>*/}
      {/*  契約アドレス確認*/}
      {/*</Button>*/}
      <Popconfirm
        title="削除"
        description="削除しますか？"
        onConfirm={deleteItems}
        okText="Yes"
        cancelText="No"
      >
        <Button
          style={{
            marginRight: '20px',
          }}
          type="primary"
          icon={<DeleteOutlined />}
        >
          削除
        </Button>
      </Popconfirm>
      <Button
        onClick={excelDownload}
        style={{
          marginRight: '20px',
        }}
        type="primary"
        icon={<FileExcelOutlined />}
        loading={loading}
      >
        excel発行
      </Button>
      <Button
        type="primary"
        onClick={handleLinkModal2Open}
        icon={<PrinterOutlined />}
        loading={loading}
      >
        未一括印刷操作
      </Button>
      <GenerateAndPrintModal open={isModalOpen} handleLinkModalClose={handleLinkModal2Close} />
    </div>
    <Table
      columns={columns}
      rowKey={(record) => record.userId}
      dataSource={data}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      // 设置多选
      rowSelection={{
        fixed: 'left', // type: 'checkbox',
        columnWidth: 25, selectedRowKeys, onChange: (selectedRowKeys) => {
          console.log(selectedRowKeys);
          setSelectedRowKeys(selectedRowKeys);
        }, preserveSelectedRowKeys: true,
      }}
    />
    <Modal
      title="詳細情報"
      open={detailVisible}
      onOk={handleDetailClose}
      onCancel={handleDetailClose}
      footer={null}
      width={1000}
    >
      <Descriptions size="small" layout="vertical" bordered items={items} column={{
        xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
      }} />

      <Popconfirm
        title="一括印刷"
        description={<>すべてのPDFをダウンロードしますか？<br />一括印刷する前に必ず「契約書作成」をしてください。</>}
        onConfirm={allPdfGet}
        okText="一括印刷"
        cancelText="取り消し"
      >
        <Button style={{
          marginRight: '15px', marginTop: '15px', backgroundColor: 'green', // 将按钮背景色设置为蓝色
          color: 'white', // 更改文字颜色为白色，以便更好地显示
        }} type="primary" loading={loading}>一括印刷</Button>
      </Popconfirm>
      <Popconfirm
        title="契約書作成"
        description={<>契約書は一度しか作成できません。<br />全てのガイド情報が正確であることを確認してください。<br />契約書と個人情報を作成しますか？<br />（誤って作成した場合は開発者にご連絡してください）</>}
        onConfirm={toPDF}
        okText="契約書作成"
        cancelText="取り消し"
      >
        <Button style={{
          marginRight: '15px', backgroundColor: 'red', color: 'white',
        }} type="primary" loading={loading}>契約書作成</Button>
      </Popconfirm>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Button style={{
          marginRight: '15px',
        }} type="primary" loading={loading}>
          選択印刷 <DownOutlined />
        </Button>
      </Dropdown>
      {/*让用户重新修改信息↓*/}
      <Button
        style={{marginRight: '15px', backgroundColor: 'deeppink', color: 'white'}}
        type="primary"
        onClick={showModal}
      >
        让用户重新修改信息
      </Button>
      <Modal
        title="修改用户信息"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
        width={800}
      >
        <Descriptions
          size="small"
          layout="vertical"
          bordered
          column={{
            xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
          }}
        >
          {items.map(item => (
            <Descriptions.Item key={item.key} label={<Checkbox onChange={() => handleCheckboxChange(item.key)}
                                                               checked={selectedKeys.includes(item.key)}>{item.label}</Checkbox>}>
              {item.content}
            </Descriptions.Item>))}
        </Descriptions>
        <Input.TextArea
          rows={4}
          placeholder="请输入修改备注"
          value={remarks}
          onChange={handleRemarksChange}
        />
      </Modal>
      <Modal
        title="确认发送"
        visible={confirmVisible}
        onOk={handleSend}
        onCancel={handleConfirmCancel}
        okText="发送，功能未完成，禁止发送！！！！！！！！！！"
        cancelText="取消"
        maskClosable={false}
      >
        <p>您选择的数据：</p>
        <ul>
          {selectedKeys.map(key => {
            const item = items.find(i => i.key === key);
            return <li key={key}>{item.label}</li>;
          })}
        </ul>
        <p>备注内容：功能未完成，禁止发送！！！！！！！！！！</p>
        <p>{remarks}</p>
        <p>用户ID：{dataRecord.userId}</p>
        <p>用户邮箱：{dataRecord.userEmail}</p>
      </Modal>
      {/*让用户重新修改信息↑*/}

    </Modal>

    <Modal title="link発行" open={linkModaVisible} onOk={handleLinkModalClose} onCancel={handleLinkModalClose}>
      <p>URL: {linkAddress.url}</p>
      <p>Message: {linkAddress.message}</p>
    </Modal>

    <Modal title="編集" width={1000} open={editVisible} onOk={handleEditOK} onCancel={handleEditClose}>

      <Form name="user-form" form={form} layout="vertical">
        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
          <Form.Item label="ローマ姓名" style={{marginBottom: 0, width: '48%'}}>
            <Form.Item
              name="englishSurname"
              rules={[{required: true}]}
              style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
            >
              <Input placeholder="ローマ姓" />
            </Form.Item>
            <Form.Item
              name="englishGivenName"
              rules={[{required: true}]}
              style={{display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px'}}
            >
              <Input placeholder="ローマ名" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="本国姓名" style={{marginBottom: 0, width: '48%'}}>
            <Form.Item
              name="chineseSurname"
              style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
            >
              <Input placeholder="本国姓" />
            </Form.Item>
            <Form.Item
              name="chineseGivenName"
              style={{display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px'}}
            >
              <Input placeholder="本国名" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="日本姓名" style={{marginBottom: 0, width: '48%'}}>
            <Form.Item
              name="japaneseCommonSurname"
              style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
            >
              <Input placeholder="日本姓" />
            </Form.Item>
            <Form.Item
              name="japaneseCommonGivenName"
              style={{display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px'}}
            >
              <Input placeholder="日本名" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="フリガナ" style={{marginBottom: 0, width: '48%'}}>
            <Form.Item
              name="furiganaSurname"
              rules={[{required: true}]}
              style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
            >
              <Input placeholder="フリガナ姓" />
            </Form.Item>
            <Form.Item
              name="furiganaGivenName"
              rules={[{required: true}]}
              style={{display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px'}}
            >
              <Input placeholder="フリガナ名" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="性别" name="gender" style={{width: '48%'}}>
            <Radio.Group>
              <Radio value="male">男</Radio>
              <Radio value="female">女</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="生年月日" name="dateOfBirth" style={{width: '48%'}}>
            <DateOfBirthPicker />
          </Form.Item>

          <Form.Item label="メールアドレス" name="userEmail" style={{width: '48%'}}
                     rules={[{type: 'email'}]}>
            <Input />
          </Form.Item>

          <Form.Item label="電話番号" name="phoneNumber" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="郵便番号" name="postalCode" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="住所" name="address" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="緊急連絡先名" name="emergencyContactName" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="緊急連絡先電話" name="emergencyContactPhoneNumber" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="緊急連絡先関係" name="emergencyContactRelationship" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="口座名義" name="accountHolderName" style={{width: '48%'}}>
            <Select>
              <Option value="本人名義">本人名義</Option>
              <Option value="法人名義">法人名義</Option>
              <Option value="家人名義">家人名義</Option>
            </Select>
          </Form.Item>

          <Form.Item label="金融機関名" name="financialInstitutionName" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="支店" name="branchName" style={{width: '48%'}}>
            <Input />
          </Form.Item>

          <Form.Item label="口座番号" name="accountNumber" style={{width: '48%'}}>
            <Input maxLength={7}
                   onKeyPress={(e) => {
                     if (!/^[0-9]$/.test(e.key)) {
                       e.preventDefault();
                     }
                   }}
                   onChange={(e) => {
                     const value = e.target.value;
                     if (!/^[0-9]*$/.test(value)) {
                       e.preventDefault();
                     }
                   }} />
          </Form.Item>

          <Form.Item
            label="個人身分証明書一（表）"
            name="selfIdentificationDocumentImaged" // 这个也会被作为表单项的键名
            valuePropName="fileList"
            getValueFromEvent={idImgFirst} // 这个配置也是必须的 不然会报错 但实际上我没有用到 idImgFirst函数可以去antd找一找 我没有改动过
          >

            <Upload
              name="SW&SqlUpload"
              listType="picture"
              // 阻止文件的上传 待点击提交按钮的时候一次性上传
              // beforeUpload={() => Promise.resolve(false)}
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="個人身分証明書二（裏）"
            name="selfIdentificationDocumentImageSecd" // 这个也会被作为表单项的键名
            valuePropName="fileList"
            getValueFromEvent={idImgSec} // 这个配置也是必须的 不然会报错 但实际上我没有用到 idImgFirst函数可以去antd找一找 我没有改动过
          >

            <Upload
              name="SW&SqlUpload"
              listType="picture"
              // 阻止文件的上传 待点击提交按钮的时候一次性上传
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="銀行口座または通帳の写真"
            name="bankDocumentImageSecd" // 这个也会被作为表单项的键名
            valuePropName="fileList"
            getValueFromEvent={bankImgSec} // 这个配置也是必须的 不然会报错 但实际上我没有用到 idImgFirst函数可以去antd找一找 我没有改动过
          >

            <Upload
              name="SW&SqlUpload"
              listType="picture"
              // 阻止文件的上传 待点击提交按钮的时候一次性上传
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="本人および名義人の氏名が記載された住民票"
            name="familyselfIdentificationDocumentImageSecd" // 这个也会被作为表单项的键名
            valuePropName="fileList"
            getValueFromEvent={familyImgSec} // 这个配置也是必须的 不然会报错 但实际上我没有用到 idImgFirst函数可以去antd找一找 我没有改动过
          >

            <Upload
              name="SW&SqlUpload"
              listType="picture"
              // 阻止文件的上传 待点击提交按钮的时候一次性上传
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="日本語通称名が記載された書類"
            name="japNameselfIdentificationDocumentImageSecd" // 这个也会被作为表单项的键名
            valuePropName="fileList"
            getValueFromEvent={japNameImgSec} // 这个配置也是必须的 不然会报错 但实际上我没有用到 idImgFirst函数可以去antd找一找 我没有改动过
          >

            <Upload
              name="SW&SqlUpload"
              listType="picture"
              // 阻止文件的上传 待点击提交按钮的时候一次性上传
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  </div>);
};
export default App;