import React, {useState} from 'react';
import './Notice.css';

const Notice = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleNotice = () => {
    setExpanded(!expanded);
  };

  return (<div className="form-group">
    <label htmlFor="notice">本人確認書類アップロードの注意事項</label>
    <div id="noticeContainer" className={`notice-container ${expanded ? 'expanded' : ''}`}>
      <p>指定の公的身分証明書をスマートフォンなどのカメラで撮影し、画像ファイルをアップロードしてください。(ファイル形式：JPEG/PNG/GIFのいずれか
        ファイルサイズ：1枚あたり10MBまで)</p>
      <p>撮影時はピントを合わせ、光の反射や影が入っていないか、また公的身分証明書の全体が入っているかをご確認ください。</p>
      <p>登録情報と本人確認書類の情報が一致しない場合、塗りつぶしや隠し、加工または不備がある場合は本人確認が完了しませんのでご注意ください。</p>
      <h3>ご提出時の注意点</h3>
      <p>書類全体が写った画像をご提出ください</p>
      <img
        src="https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/601732/3effc7a9efca00b43d1480add8434250_2f4c32b39eb33ce1a5d04b1e03194eac.png?width=1000"
        alt="書類全体"
        className="responsive-img"
      />
      <p>以下に該当する場合、否認となりますのでご注意ください</p>
      <img
        src="https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/601732/efe91bc947283957a3c3da9bffa10500_a3305828d59e9db999bf91d189f4b127.png?width=1000"
        alt="否認注意"
        className="responsive-img"
      />
      <h3>各対象本人確認書類に関する注意事項</h3>

      <h4>在留カード・特別永住者証明書</h4>
      <img
        src="https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/601732/2d1da352bd022c5cc9368188e5a550f8_de2e1c8789f166a0b16a47930b8974b2.png?width=1000"
        alt="在留カード"
        className="responsive-img"
      />
      <ul>
        <li>氏名と生年月日が一致していること</li>
        <li>※新規登録の際、公的書類の氏名に旧漢字での表記がある場合は公的書類と同様の漢字を入力してください</li>
        <li>有効期限以内であること</li>
        <li>内容判別可能な状態であること</li>
        <li>公印のある裏面も提出してください</li>
      </ul>

      <h4>運転免許証</h4>
      <img
        src="https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/601732/7c54c281aebc3c6981fb8d7b260adb8e_ab00ea070cdfe2d50bfff702f9a14d41.png?width=1000"
        alt="書転免許証"
        className="responsive-img"
      />
      <ul>
        <li>氏名と生年月日が一致していること</li>
        <li>※新規登録の際、公的書類の氏名に旧漢字での表記がある場合は公的書類と同様の漢字を入力してください</li>
        <li>有効期限以内であること</li>
        <li>内容判別可能な状態であること</li>
        <li>裏面に記載がある場合裏面も提出してください</li>
      </ul>

      <h4>マイナンバーカード</h4>
      <img
        src="https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/601732/c059ea6c505ac244641791cf9a7e0b56_a503a2c9eec1665ad6a5e13d2333cfd3.png?width=1000"
        alt="マイナンバー"
        className="responsive-img"
      />
      <ul>
        <li>氏名と生年月日が一致していること</li>
        <li>※新規登録の際、公的書類の氏名に旧漢字での表記がある場合は公的書類と同様の漢字を入力してください</li>
        <li>有効期限以内であること</li>
        <li>内容判別可能な状態であること</li>
      </ul>

      <h4>健康保険証</h4>
      <img
        src="https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/601732/307b287a590083998edcd0cae3b9f14b_16f296cf7a19f823f8e962e3e5507e60.png?width=1000"
        alt="健康保険証"
        className="responsive-img"
      />
      <ul>
        <li>氏名と生年月日が一致していること</li>
        <li>※新規登録の際、公的書類の氏名に旧漢字での表記がある場合は公的書類と同様の漢字を入力してください</li>
        <li>有効期限以内であること</li>
        <li>内容判別可能な状態であること</li>
        <li>紙型保険証の場合自身の名前が記載されているページを提出してください</li>
      </ul>
      {/*<h4>パスポート</h4>*/}
      {/*<ul>*/}
      {/*  <li>氏名と生年月日が一致していること</li>*/}
      {/*  <li>※新規登録の際、公的書類の氏名に旧漢字での表記がある場合は公的書類と同様の漢字を入力してください</li>*/}
      {/*  <li>有効期限以内であること</li>*/}
      {/*  <li>内容判別可能な状態であること</li>*/}
      {/*  <li>顔写真ページ、所持人記入ページを提出してください</li>*/}
      {/*</ul>*/}

    </div>
    <button id="toggleButton" className="toggle-button" onClick={toggleNotice}>
      {expanded ? '非表示' : '表示'}
    </button>
  </div>);
};

export default Notice;