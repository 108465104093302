import React from 'react';
import './InvalidPage.css';

const InvalidPage = () => {
  return (
    <div className="invalid-container">
      <div className="invalid-content">
        <h1 className="invalid-title">契約のURLは無効または期限切れです</h1>
        <p className="invalid-message">リンクの有効性を確認するか、新しい契約リンクを取得するために管理者に連絡してください。</p>
        {/*<a href="/" className="home-button">ホームページに戻る</a>*/}
      </div>
    </div>
  );
};

export default InvalidPage;
