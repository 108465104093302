import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Form, message} from 'antd';
import axios from 'axios';

function CheckToken() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const signBoxRef = useRef(null);
  const [signBoxWidth, setSignBoxWidth] = useState(0);
  const [formSign] = Form.useForm();

  useEffect(() => {
    // 获取 URL 中的哈希部分
    const hashString = window.location.hash;
    let token = '';
    let userId = '';
    let keys = [];
    if (hashString && hashString.includes('token=') && hashString.includes('userId=')) {
      // 分割哈希部分字符串，以 token= 为分隔符，获取键值对数组
      const tokenArray = hashString.split('token=');
      // 获取数组的第二个元素，即 token 值
      token = tokenArray[1].split('&')[0];
      userId = hashString.split('userId=')[1].split('&')[0];
      const keysArray = hashString.split('keys=')[1];
      keys = keysArray ? keysArray.split(',') : [];
    }

    async function getList() {
      try {
        const res = await axios.get(`https://sign.jtmh.jp/api/editPageByToken?token=${token}&userId=${userId}`);
        setEmail(res.data.email);

        if (res.data.status === 'notSubmitted') {
          message.success('内容を順番に入力してください');
          navigate('/editPage', { state: { user: res.data.data, editableKeys: keys } }); // 跳转到表单页面并传递用户数据和可编辑字段
        } else if (res.data.status === 'submitted') {
          message.error('情報の提出が成功しました。再提出はご遠慮ください。');
          navigate('/submittedPage'); // 跳转到已提交的页面
        } else if (res.data.status === 'invalid') {
          navigate('/invalidPage'); // 跳转到签约网址已失效或过期页面
        } else {
          navigate('/notFoundPage'); // 其他情况跳转到404页面
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.status === 'invalid') {
            navigate('/invalidPage');
          } else {
            navigate('/notFoundPage');
            message.error('Bad Request: ' + error.response.data.message);
          }
        } else {
          navigate('/notFoundPage');
          message.error('An unexpected error occurred');
        }
      }
    }

    getList();
    const width = signBoxRef.current.clientWidth;
    setSignBoxWidth(width);
    // 从 sessionStorage 中读取表单数据并填充表单
    const storedFormData = sessionStorage.getItem('formData');
    if (storedFormData) {
      formSign.setFieldsValue(JSON.parse(storedFormData));
      sessionStorage.removeItem('formData'); // 清除 sessionStorage 中的数据
    }
  }, [navigate]);

  return <div ref={signBoxRef}>Checking token...</div>;
}

export default CheckToken;
