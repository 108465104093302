import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Descriptions, message, Card, Row, Col } from 'antd';
import { request } from '@/utils';
import './ConfirmationPage.css'; // 创建一个CSS文件用于自定义样式

const fieldLabels = {
  gender: '性别',
  country: '国籍',
  englishSurname: 'ローマ字姓',
  englishGivenName: 'ローマ字名',
  chineseSurname: '本国姓',
  chineseGivenName: '本国名',
  japaneseCommonSurname: '日本姓',
  japaneseCommonGivenName: '日本名',
  furiganaSurname: 'フリガナ姓',
  furiganaGivenName: 'フリガナ名',
  phoneNumber: '電話番号',
  postalCode: '郵便番号',
  address: '住所',
  emergencyContactName: '緊急連絡先名',
  emergencyContactPhoneNumber: '緊急連絡先電話',
  emergencyContactRelationship: '緊急連絡先関係',
  accountHolderName: '口座名義',
  financialInstitutionName: '金融機関',
  branchCode: '支店コード',
  branchName: '支店名',
  accountNumber: '口座番号',
  userEmail: 'メールアドレス',
  identificationDocumentImage: '日本姓名証明書',
  selfIdentificationDocumentImage: '身分証明書（表）',
  selfIdentificationDocumentImage2: '身分証明書（裏）',
  bankAccountImage: '銀行口座写真',
  familyMemberResidenceCertificateImage: '本人および名義人が記載された住民票',
  userSignImage: '情報セキュリティ守るために、全ての画像は表示されません、ご安心ください'
  // Add more fields as necessary
};

const genderMapping = {
  male: '男',
  female: '女'
};

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { payload } = location.state || {};

  const handleEdit = () => {
    // navigate('/', { state: { formData: payload.userData } });
    navigate(-1); // 使浏览器回到上一页
  };

  const handleSubmit = async () => {
    try {
      const res = await request.post('/submit', payload);
      if (res === '签约申请保存成功') {
        message.success('提出しました！ありがとうございました。');
        navigate('/submittedPage');
      } else {
        message.error(res);
      }
    } catch (error) {
      message.error('An unexpected error occurred');
    }
  };

  if (!payload) {
    return <div>確認するデータがありません</div>;
  }

  return (
    <div className="confirmation-page">
      <Card className="confirmation-card">
        <h2 className="confirmation-title">JTMHガイド契約情報確認ページ</h2>
        <Descriptions bordered column={1} className="confirmation-descriptions">
          {Object.entries(payload.userData).map(([key, value]) => (
            <Descriptions.Item label={fieldLabels[key] || key} key={key}>
              {key === 'gender' ? genderMapping[value] : value}
            </Descriptions.Item>
          ))}
        </Descriptions>
        <Row gutter={16} className="confirmation-buttons">
          <Col span={12}>
            <Button className="edit-button" block onClick={handleEdit}>
              修正
            </Button>
          </Col>
          <Col span={12}>
            <Button className="submit-button" type="primary" block onClick={handleSubmit}>
              確認して送信
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};


export default ConfirmationPage;
