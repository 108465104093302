import Layout from '@/pages/Layout';
import Login from '@/pages/Login';
import Usersign from '@/pages/Usersign';
import Home from '@/pages/Home';
import Email from '@/pages/Email';
import Log from '@/pages/Home/Log';
import Second from '@/pages/second';
import {createHashRouter} from 'react-router-dom';
import {AuthRoute} from '@/components/AuthRoute';
import SubmittedPage from '@/pages/Usersign/UsersignSecondPage/SubmittedPage';
import InvalidPage from '@/pages/Usersign/UsersignSecondPage/InvalidPage';
import NotFoundPage from '@/pages/Usersign/UsersignSecondPage/NotFoundPage';
import ConfirmationPage from '@/pages/Usersign/ConfirmationPage';
import UserEdit from '@/pages/UserEdit';
import EditPage from '@/pages/UserEdit/editPage';

const router = createHashRouter([{
  path: '/', element: <AuthRoute><Layout /></AuthRoute>, children: [{
    index: true, element: <Home />,
  }, {
    path: 'log', element: <Log />,
  }, //   {
    //   path: 'second', element: <Second />,
    // }
  ],
}, {
  path: 'login', element: <Login />,
}, {
  path: 'usersign', element: <Usersign />,
}, {
  path: 'userEdit', element: <UserEdit />,
}, {
  path: 'editPage', element: <EditPage />,
}, {
  path: 'email', element: <Email />,
}, {
  path: 'submittedPage', element: <SubmittedPage />,
}, {
  path: 'invalidPage', element: <InvalidPage />,
}, {
  path: 'confirmationPage', element: <ConfirmationPage />,
}, {
  path: '*', // 通配符路径，匹配所有未定义的路由
  element: <NotFoundPage />,
}]);

export default router;
