import {createSlice} from '@reduxjs/toolkit';
import {removeToken, request} from '@/utils';
import {setToken as _setToken, getToken} from '@/utils';
import {jwtDecode} from 'jwt-decode'; // 导入用于解码JWT的库

const userStore = createSlice({
  name: 'user', // 数据状态，两个数据
  initialState: {
    token: getToken() || '', userInfo: [],
  }, // 同步修改方法，设置它们的修改方法
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      _setToken(action.payload);
    }, setUserInfo(state, action) {
      state.userInfo = action.payload;
    }, clearUserInfo(state) {
      state.token = '';
      state.userInfo = {};
      removeToken();
    },
  },
});

// 解构出actionCreate
const {setToken, setUserInfo, clearUserInfo} = userStore.actions;

// 获取reducer函数
const userReducer = userStore.reducer;

const fetchLogin = (loginForm) => {
  return async (dispatch) => {
    try {
      const res = await request.post('/login', loginForm);
      if (res && res.status === '200') {
        // dispatch(setToken(res.data.token))
        const decodedToken = jwtDecode(res.data);
        dispatch(setUserInfo(decodedToken.sub));
        dispatch(setToken(res.data));
      }
      return res;
    } catch (error) {
      return {message: 'ログインに失敗しました', status: '500', data: null};
    }
  };
};


const fetchUserInfo = () => {
  return async (dispatch) => {
    const res = await request.get('/user/profile');
    dispatch(setUserInfo(res.data));
  };
};

export {setToken, fetchLogin, fetchUserInfo, clearUserInfo};

export default userReducer;