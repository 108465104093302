import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Spin, Image } from 'antd';
import axios from 'axios';

const SecureImageDisplay = ({ imageUrl, token }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(imageUrl, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const imageBlob = new Blob([response.data], { type: response.data.type });
        const imageSrc = URL.createObjectURL(imageBlob);
        setImageSrc(imageSrc);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the image:', error);
        setImageSrc('');
        setLoading(false);
      }
    };
    if (imageUrl) {
      fetchImage();
    }
    else {
      setImageSrc('');
      setLoading(false);
    }

  }, [imageUrl, token]);

  return (
    <div>
      {loading ? <Spin /> : <Image src={imageSrc} alt="Secure" style={{ width: 200 }} />}
    </div>
  );
};

SecureImageDisplay.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default SecureImageDisplay;
