import React from 'react';
import './SubmittedPage.css';

const SubmittedPage = () => {
  return (
    <div className="submitted-container">
      <div className="submitted-content">
        <h1 className="submitted-title">提出が完了しました</h1>
        <p className="submitted-message">情報の提出が成功しました。再提出はご遠慮ください。</p>
        {/*<a href="/" className="home-button">ホームページに戻る</a>*/}
      </div>
    </div>
  );
};

export default SubmittedPage;
