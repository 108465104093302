import {Layout, Menu, Popconfirm} from 'antd';
import {HomeOutlined, LogoutOutlined, UserOutlined, FileSearchOutlined} from '@ant-design/icons';
import './index.scss';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {clearUserInfo} from '@/store/modules/user';
import {getToken} from '@/utils';
import {jwtDecode} from 'jwt-decode';
import {useState, useEffect} from 'react';

const {Header, Sider} = Layout;

const GeekLayout = () => {
  const navigate = useNavigate();
  const onMenuClick = (route) => {
    const path = route.key;
    navigate(path);
  };
  const location = useLocation();
  const selectedKey = location.pathname;
  const dispatch = useDispatch();
  const token = getToken();
  const decodedToken = jwtDecode(token);
  const name = decodedToken.adminName;
  const now = new Date(); // 获取当前时间
  const timestamp = now.getTime() / 1000;// 将当前时间转化成时间戳
  const [collapsed, setCollapsed] = useState(true); // 初始状态为隐藏

  // 检查JWT是否过期
  if (decodedToken.exp < timestamp) {
    dispatch(clearUserInfo());
    navigate('/login');
  }

  const [items, setItems] = useState([{label: '契約リスト', key: '/', icon: <HomeOutlined />}]);

  // 在初始渲染时检查角色，并设置菜单项
  useEffect(() => {
    const role = decodedToken.role;
    if (role === 'admin') {
      setItems(prevItems => [...prevItems, {label: 'データログ', key: '/log', icon: <FileSearchOutlined />}]);
    }
  }, [decodedToken.role]);

  const onConfirm = () => {
    dispatch(clearUserInfo());
    navigate('/login');
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (<Layout>
    <Header className="header">
      <div className="layout-title">JTMH ガイド契約システム</div>
      <div className="user-info">
          <span className="user-name">
            <UserOutlined style={{marginRight: '8px'}} />
            {name}
          </span>
        <span className="user-logout">
            <Popconfirm title="ログアウトしますか" okText="ログアウト" cancelText="キャンセル" onConfirm={onConfirm}>
              <LogoutOutlined /> ログアウト
            </Popconfirm>
          </span>
      </div>
    </Header>
    <Layout>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} width={165} className="site-layout-background">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[selectedKey]}
          items={items}
          onClick={onMenuClick}
          style={{height: '100%', borderRight: 0}}
        />
      </Sider>
      <Layout className="layout-content" style={{padding: 20}}>
        <Outlet />
      </Layout>
    </Layout>
  </Layout>);
};

export default GeekLayout;
